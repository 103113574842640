const INFO = {
	main: {
		title: "Arihant Kochhar",
		name: "Arihant Kochhar",
		email: "arihant8kochhar@gmail.com",
		logo: "../../fotor-ai.jpg",
	},

	socials: {
		twitter: "https://twitter.com/_arihant_",
		github: "https://github.com/arihantkode",
		linkedin: "https://www.linkedin.com/in/arihant-kochhar-45a78b39/",
		instagram: "https://www.instagram.com/seeker___voyager___seer/",
		facebook: "https://www.facebook.com/arihant.kochhar/",
	},

	homepage: {
		title: "Arihant",
		description: "Hello there! welcome to my little space on this web. Head over to about me page to know a bit more about me. Namaste!",
	},

	about: {
		title: "I'm Arihant",
		description:
			"Hey there! seems like you are interested inknowing me. I am a software engineer by the day, a musician by the evening and a philosopher by the night. On weekends, I tend to my terrace garden and maybe go to the mountains.",
	},

	articles: {
		title: "",
		description:
			"",
	},

	projects: [],
};

export default INFO;
